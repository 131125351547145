import React from "react"
import {
    Container,
    Row,
    Col,
    Carousel
} from 'react-bootstrap'
import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import '../css/qualidade.css'



class Services extends React.Component {

    render() {
        return (
            <>
                <Metatags
                    title="Maxipack"
                    description="Manuseio e embalagem, Montagem de kits promocionais, Re-packer - Embalagem secundária, Sistema termoencolhível (shrink), Flow Pack, Portifólio e Selagem"
                    url="https://www.maxipack.com.br/servicos/"
                    image="https://www.maxipack.com.br/maxipack.png"
                    keywords="embalagem, montagem de kits promocionais, kits promocionais, re-packer, embalagem secundária, sistema termoencolhível, shrink, flow pack, selagem"
                />
                <Navbar />
                <div className="space-50" style={{ height: "50px" }} />
                <Container>
                    <Row>
                        <Col>
                            <img
                                className="d-block w-100"
                                src={require("../images/Testeira_Servicos_1.jpg")}
                                alt="Quem somos"
                            />
                        </Col>
                    </Row>
                    <div className="space-50" style={{ height: "50px" }} />
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <div className="vertical-green-dash" />
                            <div className="quality-block">
                                <h4 style={{ marginBottom: 0 }}>Manuseio e embalagem</h4>
                                <p>A Maxi Pack tem como objetivo desenvolver soluções personalizadas de acordo com a necessidade e exigência de cada cliente com eficiência na linha de produção.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="space-50" style={{ height: "50px" }} />
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <div className="vertical-green-dash" />
                            <div className="quality-block">
                                <h4 style={{ marginBottom: 0 }}>Montagem de kits promocionais</h4>
                                <p>Equipe especializada na montagem de kits promocionais personalizados com manuseio em linha, ganhando agilidade, qualidade e segurança para atender a demanda específica de cada cliente.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="space-50" style={{ height: "50px" }} />
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <div className="vertical-green-dash" />
                            <div className="quality-block">
                                <h4 style={{ marginBottom: 0 }}>Re-packer - Embalagem secundária</h4>
                                <p>Estrutura de reempacotamento para garantir uma proteção extra ao seu produto ou kit promocional a fim de facilitar o manuseio, armazenagem e transporte até o consumidor final. </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="space-50" style={{ height: "50px" }} />
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <div className="vertical-green-dash" />
                            <div className="quality-block">
                                <h4 style={{ marginBottom: 0 }}>Metodos de embalagem</h4>
                                <p>Sistema termoencolhível (shrink), Flow Pack, Portifólio e Selagem.<br />
                                Processos essenciais para finalização das embalagens para oferecer mais segurança, higiene e facilidade no transporte.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="space-50" style={{ height: "50px" }} />
                </Container>
                <Container>
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <Carousel interval={3000}>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={require("../images/Carrossel_Servicos_MaxiPack_1.jpg")}
                                        alt="First slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={require("../images/Carrossel_Servicos_MaxiPack_2.jpg")}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={require("../images/Carrossel_Servicos_MaxiPack_3.jpg")}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={require("../images/Carrossel_Servicos_MaxiPack_4.jpg")}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={require("../images/Carrossel_Servicos_MaxiPack_5.jpg")}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={require("../images/Carrossel_Servicos_MaxiPack_6.jpg")}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={require("../images/Carrossel_Servicos_MaxiPack_8.jpg")}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={require("../images/Carrossel_Servicos_MaxiPack_7.jpg")}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                    <div className="space-50" style={{ height: "50px" }} />
                </Container>
                <Container style={{ padding: 0 }}>
                </Container>
                <Footer />

            </>
        )
    }
}

export default Services